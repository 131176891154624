.App {
  text-align: center;
  box-sizing: content-box;
}

@media print {
  table {
    page-break-inside: avoid;
  }
  @page {
    margin: 0mm !important;

  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.clickable-box-header {
  display: flex;
  flex-direction: row;
  user-select: none;
}
.clickable-box-header:hover {
  cursor: pointer;
}
.hardware-toggle-switch {
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.order-body {
  background-color: #fff;
}

.header-top-wrapper {
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 4vmin;
  background-image: linear-gradient( #4e7938, #455b3b);
  background-color: #4e7938;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #d9d9db;
  box-shadow: 10px 10px 10px gray;
  font-size: 0.7em;
}




























/*HEADER*/
.header-info-wrapper {
  padding-top: 0.5%;
  display: flex;
  flex-direction: row;
  margin: 0% 10%;
  height: 50%;
  width: 80%;
  border: 2px solid black;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 0.5%
  }
.header-info {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  height: 47%;
  width: 48%;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.header-text {
  margin-left: 5%;
  font-size: 1.1em;
  text-align: left;
}





/*PAGE*/
.page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-size: 0.8em;
}
.page-wrapper.condensed {
  height: 25vh;
}
.page-wrapper.cover {
  height: 200vh;
}
.chunk-wrapper {
  width: 95vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.body-wrapper {
  width: 95%;
  height: 95%;
  border: 2px solid black;
}
.body-wrapper.condensed {
  width:100vw;
  height:100%;
  border: none;
  margin-bottom: 5px;
  border-bottom : none;
}
.body-wrapper.cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}








.logo-text-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.logo-text {
  font-size: 3rem;

}
.cover-logo-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 4rem;
}












.page-header {
  font-size: x-large;
  height: 3%;
  text-align: center;
  text-decoration: underline;
}


.totals-table {
  table-layout: auto;
  width: 100%;
  height: 93%;
  border: 2px solid black;
  color:black;
  font-size: 0.6em;
  
}
.totals-table.counts {
  height: auto;
  
  margin-top: 5mm;
  margin-bottom: 5mm;
}

.totals-table.condensed {
  border-spacing: 0;
  padding: 2px;
  padding-top: 40px;
}

.totals-table.schedule {
  height: auto;
  width: 100%;
  /* page-break-after: always; */
}

.totals-row.counts {
  height: 20px;
}
.totals-row.condensed {
  /* border: 1px solid black; */
}
.totals-row.main {
  background-color: #c6c6c6;
}
.totals-row.schedule {
  background-color: #c6c6c6;
}
.totals-data {
  border: 1px solid black;
  text-align: left;
}
.totals-data.condensed {
  border: 1px solid black;
}
.totals-data.total-doors {
  width: 30%;
  text-align: center;
  padding-right: 1%;
  margin: 2px;
  border: 2px solid black;
}
.total-door-row {
  width: 100%;
  height: 3.7%;
  font-size: large;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: bold;
}





.logo-wrapper {
  text-align: right;
  width: 30px;
  height: 25px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: white;
  border: 1px solid black;
  padding-right: 1%;
}
.watermark-wrapper {
  width: 200px;
  height: 80px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.watermark-wrapper.header {
  width: 50px;
  width: 50px;
  left: 10px;
  bottom: 0px;
}
.logo-img {
  width: 100%;
}
.page-row-wrapper {
  width: 98.5%;
  height: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  border: 2px solid black;
  margin: .5%;
}
.page-row-wrapper.condensed {
  border: 2px solid red;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.page-row-wrapper.hardware {
  height: 69%;
  border: none;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.door-info-wrapper {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.door-info-wrapper.condensed {
  width: 100%;
  justify-content: flex-start;
}
.hardware-info-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.info-cell {
  width: 100%;
  font-size: 1.1rem;
  border-bottom: 1px solid black;
  text-align: left;
  justify-content: space-between;
}
.info-cell.condensed {
  border-bottom: none;
  border: 1px solid black;
}
.info-cell.stacked {
}
.info-cell-stack {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.info-cell.hardware {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}
.info-cell.hardware.header {
  text-align: center;
  font-size: .6rem;
}
.hardware-block-wrapper {
  width: 50%;
  height: 24%;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.hardware-block-wrapper.small {
  width: 50%;
  height: 19%;
}
.hardware-block-wrapper.tiny {
  width: 33%;
  height: 24%;
}

.hardware-line-wrapper {
  width: 100%;
  height: 4%;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.img-frame {
  width: 20%;
  height: 100%;
  border-right: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-frame.hardware {
  width: 35%;
  height: 100%;
}
.item-img {
  height: 95%;
}
.item-img.hardware {
  width: 95%;
}





@media screen and (max-height: 801px) {
  .page-wrapper {
    height: 300vh;
  }
  
}

@media screen and (max-height: 400px) {
.page-wrapper {
  height: 350vh;
}
.info-cell {
  font-size: .8rem;
}
.info-cell.hardware {
  font-size: .7rem;
}
.info-cell.hardware.tiny {
  font-size: .6rem;
}
.item-img {
  height: 90%;
}
.item-img.hardware.tiny {
  height: 60%;
}

}

@media screen and (max-width: 500px) {
  
  .page-wrapper.cover {
    display: none;
  }
  .info-cell {
    font-size: .8rem;
  }
  .info-cell.hardware {
    font-size: .7rem;
  }
  .info-cell.hardware.tiny {
    font-size: .6rem;
  }
  .item-img {
    height: 90%;
  }
  .item-img.hardware.tiny {
    height: 60%;
  }
}

