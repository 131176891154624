hr {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
  display: block;
  --whiteColor: rgb(250, 250, 250);
  --background: rgb(238, 238, 238);
  --grayColor: rgb(148, 148, 148);
  --logo-green1: rgb(122, 178, 25);
  --logo-green2: rgb(74, 116, 40);
  --redColor: red;
  --greenColor: rgb(122, 178, 25);
  --orangeColor: rgb(210, 177, 10);
  --goldenRod: goldenrod;
  background-image: linear-gradient(var(--greenColor) 200px,var(--orangeColor));
  background-size: 100%;
  min-height: 150vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}





/*HEADER & NAV BUTTONS*/
.app-header {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  user-select: none;
  
}
.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 1000px;
  max-width: 2000px;
  color: black;
}
.nav-button {
  width: 8vw;
  height: 7vmin;
  color:white;
  /* animation: hoverOff .1s ease-out; */
  /* transform: scale(1.0); */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 4px solid rgba(0,0,0,0);
}
.nav-button:hover {
  cursor: pointer;
  border-bottom: 4px solid var(--goldenRod);
  /* animation: hoverOn .1s ease-in; */
  /* transform: scale(1.15); */
}
.nav-button.active {
  filter: drop-shadow(0px 0px 5px var(--goldenRod)) drop-shadow(0px 0px 30px white);
  /* transform: scale(1.15); */
  animation: none;
  cursor: auto;
}
@keyframes hoverOn {
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.05);
  }
}
@keyframes hoverOff {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.0);
  }
}
.nav-icon {
  font-size: 4vh;
  color: white;
  pointer-events: none;
}
.nav-header {
  font-size: 1.5vh;
  color: white;
  pointer-events: none;
}








/*STANDARD PAGE CLASSES*/
.page-stage {
  padding: 3vw;
  display: flex;
  justify-content: flex-start;
  background-color: var(--whiteColor);
  border-radius: 2vh;
  flex-direction: column;
  box-shadow: 0px 0px 20px black;
  color:black;
}
.page-stage.small {
  width: 5vw;
  max-width: 700px;
  height: 100vh;
}
.page-stage.medium {
  width: 60vw;
  max-width: 1000px;
  height: 100vh;
}
.page-stage.large {
  width: 70vw;
  max-width: 1300px;
  height: 100vh;
}
.page-stage.largest {
  width: 80vw;
  max-width: 1600px;
  height: 100vh;
}
.field-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color:black;
}

.field-column.small {
  width: 31%;
}
.field-column {
  width: 48%;
}
.input-field {
  background-color: var(--background);
  width: 100%;
  font-size: 2vh;
  height: 4vh;
  border-radius: 1vh;
  margin-bottom: 1vh;
}
.input-field.button {
  user-select: none;
  box-sizing: border-box;
  border: 1px inset black;
  line-height: 4vh;
  font-size: 2;
}
.input-field.button.jobs {
  position: absolute;
  bottom: 1%;
  font-size: 1.4rem;
  width: 70%;
}
.input-field.button.jobs.green:hover {
  background-color: var(--greenColor);
}
.input-field.button.orange:hover {
  background-color: var(--goldenRod);
}
.input-field.job {
  font-size: 1rem;
  height: 3vh;
  width: 60%;
  font-size: 1rem;
  margin-bottom: 0;

}
.input-field.button:hover {
  cursor: pointer;
  color: white;
  background-color: var(--greenColor)
}
.input-field.button.jobs:hover {
  background-color: var(--goldenRod);
}
.description-field {
background-color: var(--background);
border-radius: 1vh;
width: 100%;
height: 8vh;
font-size: 2vh;
}
.signature-field {

}
.submit-button {

}
.file-field {

}
.file-field-icon {

}

.label-text {

}
.small-text {
  font-size: 0.7em;
  color:black;
}
.smallish-text {
  font-size: 0.9em;
  color:black;
}
.medium-text {
  font-size: 1.1em;
  color:black;
}
.mediumish-text {
  font-size: 1.5em;
  color:black;
}
.large-text {
  font-size: 2em;
  color:black;
}



.orange-icon {
  margin-top: 1vh;
  color: var(--goldenRod)
}

/*DATABASE STANDARD CLASSES*/
.filter-container {

}
.table-wrapper {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  border-radius: 1vh;
}

.row-wrapper:nth-child(odd) {
  background-color: var(--background);
}
.row-wrapper.top {
  font-size: large;
  background-color: var(--goldenRod);
  color: white;
  border-radius: 0vh;
  border: 1px solid black;
}

.row-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 2%;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start
}

.cell-piece {
  box-sizing: border-box;
  border-right: 1px solid black;
  line-height: 150%;
  font-size: 1rem;
}
.cell-piece.lock {
  font-size: larger;
}
.cell-piece.lock:hover {
  cursor: pointer;
  background-color: var(--greenColor);
  color: white;
}
.cell-piece.lock {
  font-size: larger;
}
.cell-piece.lock:hover {
  cursor: pointer;
  background-color: var(--goldenRod);
  color: white;
}
.cell-piece.lock.cross:hover {
  background-color: var(--redColor);
}
.cell-piece.save {
  font-size: larger;
}
.cell-piece.save:hover {
  cursor: pointer;
  background-color: var(--greenColor);
  color: white;
}
.cell-piece.desc {
  overflow: hidden;
}
.row-wrapper.header.expanded {

}
.row-wrapper.expanded {

}
.row-wrapper.footer.expanded {

}
.edit-button {

}
.save-button {

}
.close-button {

}






/*JOBS REPORT CLASSES*/
.job-stage {

}
.job-column {

}
.job-pie-wrapper {

}
.job-info-wrapper {

}
.job-expand-button {

}
.job-bar-wrapper {

}
.job-table-wrapper {

}
.job-collapse-button {

}

.staging-page {
  height: 5vh;
   width: 90%
}



.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  table-layout: fixed;
  
}

.styled-table th, .styled-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.styled-table th {
  background-color: #5a4bb7;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.styled-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tr:nth-child(odd) {
  background-color: #dadada
}

.styled-table tr:hover {
  background-color: #c1c1c1;
}

.styled-table td {
  color: #333;
}

.styled-table th:first-child,
.styled-table td:first-child {
  border-left: none;
}

.styled-table th:last-child,
.styled-table td:last-child {
  border-right: none;
}

.hover-cell {

}


/*SUBMITTALS & TAKEOFFS*/
.submittals-wrapper {
  width: 90vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.card-wrapper {
  position: relative;
  width: 380px;
  margin: .8vw;
  height: 500px;
  background-color: var(--background);
  font-size: 0.7em;
  border-radius: 2vh;
  box-shadow: 0px 0px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-wrapper.submittals {
  height: 100px;
  width: 90%;
  margin: .5vw;
  transform: scale(1);
  animation: hoverOff .1s ease-in-out;
}
.card-wrapper.submittals:hover {
  cursor: pointer;
  animation: hoverOn .1s ease-in-out;
  transform: scale(1.05);
}
.card-wrapper.docs {
  width: 400px;
  min-height: 650px;
}
.card-wrapper.job {
  width: 500px;
  min-height: 800px;
}
.card-info-wrapper.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.card-info-wrapper {
  background-color: var(--whiteColor);
  margin: 1vh;
  border-radius: 1vh;
  border: 2px solid var(--grayColor);
  width: 90%;
  overflow: hidden;
  padding: 2%;
}
.card-info-wrapper.submittals {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.docsBox-header {
  width: 70vw;
  height: 8vh;
  background-color: var(--whiteColor);
  border-radius: 2vh;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  filter: drop-shadow(0px 0px 10px);
  padding: 1%;
}
.card-info-wrapper.docs {
  display: flex;
  flex-direction: column;

}

.card-info-wrapper.job {
  display: flex;
  flex-direction: row;
  margin: 0vw;
  margin-top: .5vw;
}
.card-info-wrapper.docs.green {
  border: 3px solid green;
}
.card-info-wrapper.docs.red {
  border: 3px solid red
}
.card-info-wrapper.graph {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  margin: 0vw;
  margin-bottom: .5vw;
  margin-top: .5vw;
}
.card-info-wrapper.graph.small {
  height: 200px;
}
.card-info-wrapper.edit {
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}
.card-info-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.card-info-column.filter {
  align-items: center;
  flex-direction: column;
}
.card-info {
  display: flex;
  flex-direction: row;
  text-align: left;
}
.card-info .line {
  border-top: 2px solid black;
}
.card-info-row {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 1%;
}
.card-info.docs {
  width: 100%;
  border-bottom: 1px solid black;
}
.card-info-wrapper.image {
  height: 50%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image {
  width: 95%;
}


.card-info-wrapper.palette {
  height: 70%;
}
.color-selection-wrapper {
  border: 1px solid black;
  width: 100%;
  height: 25%;
  margin-top: 2%;
  font-size: 1.3rem;
}
.color-selection-wrapper:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

/* -LOGIN- */
.tab-wrapper.login {
  position: absolute;
  z-index: 100;
  /* background: linear-gradient(124deg, #e8b71d, #e3e81d, #1de840, #1de8c6);
  background-size: 1800% 1800%;
  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite; */
  /* background-image: linear-gradient( -10deg, #f10d5e 0%, #ffb43e 25%, #43e03c 50%, #6528ff 100%);
  animation: unicorn-slide 10s ease infinite;
  -webkit-animation: unicorn-slide 10s ease infinite;
  background-size: 200% 200%;
  overflow: hidden;*/
  min-height: 150vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient( -400deg, #f10d5e 0%, #ffb43e 40%, #43e03c 100%);
  animation: unicorn-slide 10s ease infinite;
  -webkit-animation: unicorn-slide 10s ease infinite;
  background-size: 200% 200%;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
@keyframes unicorn-slide {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

@-webkit-keyframes unicorn-slide {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}
/* @-webkit-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@keyframes rainbow { 
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
} */


#login-wrapper {
  padding: 8%;
  width: 40%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
  background-color: white;
  box-shadow: 0px 0px 20px black;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: xx-large;
  border-radius: 2vh;
}
#login-wrapper.denied {
  animation: denied 1s, shake 0.5s;
}
@keyframes denied {
  0%{box-shadow: 0px 0px 50px rgba(255, 0, 0, 0.1);}
  10%{box-shadow: 0px 0px 250px rgb(255, 166, 0);}
  80%{box-shadow: 0px 0px 50px rgba(255, 68, 0, 0.8);}
  100%{box-shadow: 0px 0px 20px rgb(0, 0, 0);}
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.login-input-wrapper {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-input {
  margin: 1%;
  font-size: 2rem;
  width: 80%;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  cursor: pointer;
  border-radius: .8vh;
}
.login-description {
  font-size: 1rem;
}

/*FILE DRAG N DROP */

.drop-container {
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  width: 99%;
  height: 20vh;
  border: 3px dashed rgb(71, 71, 71);
  border-radius: 10px;
  background-color: var(--whiteColor);
  color: black;
  position: relative;
  
}

.upload-icon {
  width: 50px;
  height: 50px;
  /* background: url(../images/upload.png) no-repeat center center;  */
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
  
}

.drop-message {
  font-weight: bold;
  text-align: center;
  color: var(--greenColor);
  font-size: 1.3rem;
  position: absolute;
}
.drop-message.noFile {
  color: var(--goldenRod)
}
.upload-button {
  height: 100%;
  font-size: 1.2rem;
  width: 100%;
  color: rgba(0,0,0,0);

}

.drop-icon {
  pointer-events: none;
  font-size: 6rem;
}
.drop-icon.mobile {
  font-size: 6vh;
}


/*OVERLAY */
.overlay-page {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.65);
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.overlay-pane-wrapper {
  position: sticky;
  top: 15vh;
  width: 30%;
  height: 40vh;
  border: 2px solid black;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 2vh;
}
.overlay-pane-info {
  color: white;
  font-size: 2rem;
}
.overlay-pane-info.fail {
  color: red;
  font-size: 1rem;
}
.overlay-pane-icon {
  font-size: 4rem;
  color: white;
}
.overlay-pane-icon.failure {
  color: red;
}

.overlay-pane-icon.loading {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
}

.input-field.button.overlay {
  width: 40%;
  height: 10%;
  background-color: var(--background)
}

.page-stage.largest {
  width: 85%;
  height: 100%;
}

@media (max-width:700px)  {
  .App {
    width: 100%;
  }
  .page-stage {
    padding: 2vw;
    display: flex;
    justify-content: flex-start;
    background-color: var(--whiteColor);
    border-radius: 2vh;
    flex-direction: column;
    box-shadow: 0px 0px 0px black;
  }
   /* smartphones, Android phones, landscape iPhone */ 
   .page-stage.small {
    width: 70%;
    height: 50vh;
    font-size: 1rem;
  }
  .page-stage.medium {
    width: 80%;
    height: 100vh;
  }
  .page-stage.large {
    width: 85%;
    height: 120vh;
  }

  .submittals-wrapper {
    width: 90%;
  }
  .app-header {
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: flex-start;
    
  }
   .nav-container {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    user-select: none;
  }
.nav-button {
  width: 10%;
}
   .nav-header {
    display: none;
   }
   #nav-settings{
    display: none;
   }
   #nav-ampDocs{
    display: none;
   }
   #nav-checks{
    display: none;
   }
   #nav-invoiceData {
    display: none;
   }

   .card-wrapper {
    width: 90%;
    margin: .8vw;
    height: 500px;
    
    border-radius: 2vh;
    box-shadow: 0px 0px 0px;
  }
  .card-wrapper.docs {
    width: 400px;
    min-height: 650px;
  }
  .card-wrapper.job {
    width: 500px;
    min-height: 800px;
  }

  .staging-page {
    height: 1vh;
     width: 90%
  }
  #login-wrapper {
    width: 70%;
  }

  .small-text {
    font-size: 0.7em;
  }
  .smallish-text {
    font-size: 0.8em;
  }
  .medium-text {
    font-size: .9em;
  }
  .mediumish-text {
    font-size: 1.0em;
  }
  .large-text {
    font-size: 1.5em;
  }

  .field-column {
    width: 45%;
    padding: 2.5%;
  }
  .input-field.button.jobs {
    font-size: 1.2em;
    width: 80%;
  }
  .description-field {
    padding: 2.5%;
  }

}
@media (min-width:801px)  {
   /* tablet, landscape iPad, lo-res laptops ands desktops */ 
}
@media (min-width:1025px) {
   /* big landscape tablets, laptops, and desktops */ 
}
@media (min-width:1281px) {
   /* hi-res laptops and desktops */ 
}