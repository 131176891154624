h2 {
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.App {
  --background: rgb(218, 218, 218);
  --background2: rgb(127, 30, 30);
  --logo-green1: rgb(122, 178, 25);
  --logo-green2: rgb(74, 116, 40);
  text-align: center;
  background-image: linear-gradient(rgb(122, 178, 25) 200px,rgb(210, 177, 10));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-header {
  background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
  min-width: 100vw;
  left: 0;
  right: 0;
  position: -webkit-fixed;
  position: fixed;
  z-index: 100;
  top: 0;
  height: 120px;
  display: flex;
  align-items: center;
  flex-direction: row;
  user-select: none;
  
}
#TabHome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#TabHome:hover {
  cursor: pointer;
  font-weight: bold;
  transform: scale(1.2);
}
#TabHome:hover #logo-amp {
  color: white;
}
#TabHome:hover #logo-concepts {
  color: white;
}
#TabHome:hover #logo-llc {
  color: white;
}

.logo-img {
  width: 120px;
  pointer-events: none;
}
.logo-text-container {
  text-align: left;
  display: block;
  pointer-events: none;

}
.logo-title {
  margin: 0;
  line-height: 40px;

}
#logo-amp {
  color: white
}
#logo-concepts {
  color: white
}
#logo-llc {
  color: white
}

.nav-button {
  height: 100%;
  width: 25%;
  color: white;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-button:hover {
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: x-large;
}

.white-backdrop {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 90vw;
  background-color: white;
}


.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
@media screen and (max-width: 1180px) {
  .App-header {
    position: sticky;
    top: 0;
    flex-wrap: wrap;
    height: 200px;
    justify-content: space-between;
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
  }
  .mainContent {}
  .nav-button {
    background-color: rgba(0,0,0,0.5);
    color: white;
    margin: 0.5%;
    width: 22%;
    height: 100px;
    border: 4px solid black;
    box-shadow: 0px 0px 8px black;
    font-size: 0.8em;
  }
  
  .nav-button:hover {
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 0.9em;
  }
  #TabHome {
    align-items: center;
    justify-content: center;
  }
  #TabLogin {
    display: none;
  }
  #logo-amp {
    color: white;
  }
  #logo-concepts {
    color: white;
  }
  #logo-llc {
    color: white;
  }
}

@media screen and (max-width: 500px) {
  .App-header {
    position: sticky;
    top: 0;
    height: 200px;
  }
  .nav-button {
    background-color: rgba(0,0,0,0.5);
    color: white;
    margin: 0.5%;
    width: 20%;
    height: 100px;
    border: 4px solid black;
    box-shadow: 0px 0px 8px black;
    font-size: 0.6em;
  }
  .nav-button:hover {
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 0.8em;
  }
  .logo-img {
    width: 70%;
  } 
  .logo-text-container {
    display: none;
  }

}