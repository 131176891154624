
@keyframes slideInFromBottom {
    0% {
      transform: translateY(10%);
      opacity: .6;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

.tab-wrapper {
  position: sticky;
  z-index: 3;
    animation: .5s ease-out 0s 1 slideInFromBottom;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    --logo-green1: rgb(122, 178, 25);
    --logo-green2: rgb(74, 116, 40);
    --background: rgb(218, 218, 218);
    width: 90vw;
    box-shadow: 0px 0px 20px black;
    background-color: rgba(255, 255, 255, 1);

}
.tab-wrapper.login {
  background-color: rgba(0, 0, 0, 0.001);
  box-shadow: none;
  width: 100vw;
  background-image: linear-gradient(rgb(122, 178, 25) 200px,rgb(210, 177, 10));
}

.header-image-wrapper {
 width: 90vw;
 box-shadow: 0px 10px 20px black;
  height: 40vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.header-image-wrapper.bottom {
  align-items: flex-end;
}
.header-image {
  width: 100vw;
}



.tab-body-wrapper {
  width: 80%;
  padding-bottom: 10%;
}
.tab-title {
  color: var(--logo-green2);
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.tab-header {
  color: goldenrod;
  text-decoration: underline;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  

}
.tab-body {
  color: black;
  font-size: 1.4rem;
  text-align: left;
}

.tab-ul {
  color: black;

}
.tab-li {
  text-align: left;
  font-size: 1.4rem;

}





.portfolio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.display-wrapper {
  display: flex;
  justify-content: center;
  width: 40%;
  height: 400px;
  position: relative;
  border: 1px solid black;
  box-shadow: 0 0 10px black;
  margin: 1%;
  transition: all .2s ease-in-out;
}
.display-wrapper:hover {
  scale: 1.1;
}
.display-wrapper:hover .image-display-description {
  background-image: linear-gradient(rgba(45, 45, 45, 0.1) 5%,rgba(0, 0, 0, 0.001) 60%, rgba(45, 44, 44, 0.1));
}



.image-display {
  width: 100%;
  height: 100%;
}
.image-description-wrapper {
  position: absolute;
}
.image-display-description {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgb(45, 45, 45) 5%,rgba(0, 0, 0, 0.001) 60%, rgb(45, 44, 44));
}
.image-display-description2 {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
  position: absolute;
  z-index: 2;
  bottom: 0;
}










.login-wrapper {
  padding: 8%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--logo-green2);
  background-color: white;
  border: 4px solid rgb(40, 40, 40);
  box-shadow: 0px 0px 20px black;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.login-input-wrapper {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-input {
  margin: 1%;
  font-size: 2rem;
  width: 100%;
}
.login-description {
  font-size: 1rem;
}



@media screen and (max-width: 1180px) {
  .display-wrapper {
    width: 80%;
    height: 400px;
  }

}
@media screen and (max-width: 500px) {
  .display-wrapper {
    height: 180px;
  }
  .header-image-wrapper {
    height: 20vh;
  }
}